<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/detail-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Paket</span></router-link></li>            
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengukuran-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengukuran Paket</span></router-link></li>            
            <li class="breadcrumb-item active" aria-current="page">Pengiriman Paket</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5"  v-for="(item, index) in data" :key="index" @click="onClickEventDiscount(item)"  :style="{backgroundImage: `'url(${item.image})'`,cursor:'pointer'}">
                <!-- <div class="image-wrapper">
                  <div class="text-overlay">
                    <h2>Text di Atas Gambar</h2>
                  </div>
                  <div class="image" :style="{backgroundImage: 'url(' + item.image + ')'}"></div>
                </div> -->
                <img :src="item.image">
                <label>{{ item.name }}</label>
                <!-- <div class="card pb-4" v-bind:style="{ 'background-image': 'url(' + require(item.image)  + ')' }"> -->
									<!-- <div class="brand-logo text-center">
									</div> -->
									<!-- <div class="row pl-4">
										<h6 class="font-weight-bold col-lg-12">Berat Maksimal Paket : 25kg</h6>
										<h6 class="font-weight-bold col-lg-12">Batas Ukuran Paket : 60x60x60</h6>
										<h6 class="font-weight-bold col-lg-12">Gratis Waktu Tunggu : 15 Menit</h6>
									</div>																											         -->
                <!-- </div> -->
              </div>
              <div class="mt-3">
                {{ show.length }}
                <template v-if="show.length != 0">
                  <h4 class="pt-3 col-lg-12 pull-left">Kamu berhak mendapatkan diskon</h4>
                  <h6 class="pt-1 col-lg-12" style="color:#e91e63;">Rp. {{ show.discount }}</h6>
                </template>
                <!-- <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-success btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onDiscount">Voucher</b-button>-->


                <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Pakai Promo</b-button>                    
                <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
              </div>              
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      errorMassage:0,
      show : [],
      form:{        
        uuid_event_discount_user: '',
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [] 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),        
    getDiscount: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/event-discount/active?uuid_user=${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.data = response.data.result
        for (let index = 0; index < this.data.length; index++) {
          const protocol = `${document.location.protocol}//`;
          // const absoluteUrl = new URL(this.data[index].image, protocol).href;
          // const encodedUrl = encodeURIComponent(this.data[index].image)
          this.data[index].image = `${protocol}${this.data[index].image}`
          // console.log(this.data[index].image);    
          // console.log();      
        }

			})      
    },
    onClickEventDiscount(item) {      
      this.form.uuid_event_discount_user = item.uuid_event_discount_user
      this.show = item
      // packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      // axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      // .then((response) => {
      //   this.data = response
      //   this.$router.push(`/operasional/antar-barang/pengiriman-paket/${this.params.uuid}`)
      // })
    },
    onNext() {      
      // this.form.uuid_event_discount_user = id
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pengiriman-paket/${this.params.uuid}`)
      })
    }    
  },
  mounted() {
    this.getDiscount(localStorage.getItem("uuid"))        
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}


.image-wrapper {
  position: relative;
  display: inline-block;
}

.text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080; /* Ubah sesuai kebutuhan */
  color: #fff; /* Ubah sesuai kebutuhan */
  padding: 10px;
}

.image {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
}
</style>